import React from 'react';

const NewsletterForm: React.FC= () => {
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Handle form submission logic here
    };

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />
            <button type="submit">Subscribe</button>
        </form>
    );
}

export default NewsletterForm;
import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(20px);
        filter: blur(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
        filter: blur(0);
    }
`;

interface FadeInComponentProps {
    orderanimation?: number; // Starting element for animation (1-based index)
    delayDelta?: number; // Delay increment between child elements (default: 0.5s)
}

const FadeInComponent = styled.div<FadeInComponentProps>`
    & > * {
        animation: ${fadeInAnimation} 0.8s ease-out forwards;
        opacity: 0;
    }

    ${({ orderanimation = 1, delayDelta = 0.2 }) => {
        const startFromIndex = 1; // Start animation from the specified index
        let delayStyles = '';
        const initialDelay = delayDelta * (orderanimation - 1);

        // Loop through the children and apply delay
        for (let i = 0; i < 20; i++) {
            // Only apply delay starting from the orderanimation index
            const delay = i >= startFromIndex - 1
            ? `animation-delay: ${initialDelay + delayDelta * (i - (startFromIndex - 1))}s;`
            : 'animation-delay: 0s;';
            delayStyles += `
            & > *:nth-child(${i + 1}) { ${delay} }
            `;
        }

        return delayStyles;
    }}
`;

export default FadeInComponent;

import React from 'react';
import styled, { keyframes } from 'styled-components';

const colors = [
    'rgba(255, 179, 167, 0.5)', 
    'rgba(167, 255, 179, 0.5)', 
    'rgba(167, 179, 255, 0.5)',
    'rgba(255, 179, 209, 0.5)', 
    'rgba(209, 167, 255, 0.5)',
    'rgba(167, 255, 245, 0.5)', 
    'rgba(255, 209, 167, 0.5)'  
];

// Get a random color from the list
const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

// Get two different random colors
const getTwoDifferentColors = () => {
    const color1 = getRandomColor();
    let color2 = getRandomColor();
    
    // Ensure color2 is different from color1
    while (color1 === color2) {
        color2 = getRandomColor();
    }
    
    return { color1, color2 };
};

const rotate = keyframes`
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;

const GradientFrame = styled.div<{ color1: string; color2: string }>`
    background: linear-gradient(270deg, ${props => props.color1}, ${props => props.color2});
    background-size: 400% 400%; /* Makes the gradient move visibly */
    animation: ${rotate} 10s infinite alternate ease-in-out;
    transition: transform 0.3s ease;
    perspective: 1000px;
`;

const ColoredFrame: React.FC<{
    className?: string;
    description?: string;
    children: React.ReactNode;
}> = ({ className, description, children }) => {
    // Get two different colors for the gradient
    const { color1, color2 } = getTwoDifferentColors();

    return (
        <GradientFrame
            color1={color1}
            color2={color2}
            className={`flex flex-col gap-4 items-center justify-center p-2 sm:p-6 rounded-lg ${className}`}
        >
            <div
                className={`p-1 md:p-2 rounded-lg bg-black bg-opacity-20 transform transition-transform duration-300 ease-in-out overflow-hidden`}
            >
                {children}
            </div>
            {description && <div className="text-sm text-center text-text-primary"><p>{description}</p></div>}
        </GradientFrame>
    );
};

export default ColoredFrame;

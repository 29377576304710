import React from 'react';
import LayoutContent from '../BaseComponents/LayoutContent';
import ListLinksAndImages from '../BaseComponents/ListLinksAndImages';
import CustomLink from '../BaseComponents/CustomLink';
import { JsonFileNames } from '../../types';

const HomePage: React.FC = () => {
  return (
    <LayoutContent title="Emile Courthoud">
      <div className="flex mb-12 gap-4">
        <CustomLink children="About" href="/about" />
        <CustomLink children="Connect" href="/connect" />
        <CustomLink children="Blog" href="/blogs" />
      </div>
      <ListLinksAndImages jsonFileName={JsonFileNames.Projects} orderanimation={3}/>
    </LayoutContent>
  );
};
export default HomePage;